import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Header } from "../components/header";
import { Layout } from "../components/layout";
import { Leidenschaften } from "../components/leidenschaften";
import RTF from "../components/rtf";
import SEO from "../components/seo";
import { MaxWidth, READ_WIDTH } from "../utils/style";
import styled from "styled-components";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHtml(uid: { eq: "meine-leidenschaften-intro" }) {
        content {
          json
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Meine Leidenschaften" />
      <Header small>
        <>
          <div className="circle-text">
            <h1>
              Meine
              <br /> Leidenschaften
            </h1>
          </div>
          <div
            className="circle"
            style={{
              backgroundPosition: "50% 50%",
              backgroundImage: `url(${require("../images/kal-visuals-657624-unsplash.jpg")})`
            }}
          />
        </>
      </Header>

      <MaxWidth maxWidth={READ_WIDTH}>
        <IntroDiv>
          <RTF content={data.contentfulHtml.content.json} />
        </IntroDiv>
      </MaxWidth>

      <Leidenschaften />
    </Layout>
  );
};

export default IndexPage;

const IntroDiv = styled.div`
  padding: 3rem 0;
  h3 {
    text-align: center;
  }
`;
